import React, { useState, useEffect } from 'react';
import AllArticleData from '../../data/new-blog-data.json';
import { Footer } from '../components/layouts/Footer';
import { PageContainer } from '../components/containers/PageContainer';
import { HelmetContainer } from '../components/pageComponents/helmet';
import { Header } from '../components/layouts/HeaderComponent';
import GlobalStyles from '../GlobalStyles';
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

gsap.registerPlugin(ScrollToPlugin);

import {
  PaginationStyles,
  NewsGrid
} from './../styles/newsStyles';
import { SingleArticle } from '../components/V2/ArticleBlock/SingleArticle/SingleArticle';
import to = gsap.to;
import { NoResultsDisplay } from './../styles/newsStyles';

interface Props {
  baseCategory?: object;
  lang?: string;
}

const NewsTemplate = ({ baseCategory = [], lang = 'en', ...props }: Props) => {
  const [articles, setArticles] = useState([]);
  const [contentLoaded, setContentLoaded] = useState(false);
  const articlesPerPage = 13; // Number of articles to display per page
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCategories, setSelectedCategories] = useState(baseCategory);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [filteredArticles, setFilteredArticles] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [openFilters, setOpenFilters] = useState(false);

  const Copy = require(`../../data/content/News/${lang}.json`);

  console.log(lang);

  useEffect(() => {
    const fetchData = () => {
      setTimeout(() => {
        setContentLoaded(true);
        setArticles(AllArticleData.data.allDibPosts.edges);
      }, 100);
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (articles && articles.length > 0) {
      const uniqueCategories = Array.from(
        new Set(articles.flatMap(article => article.node.categories.map(category => category.slug)))
      );
      uniqueCategories.sort();

      setFilteredArticles(
        articles.filter(article => {
          let categoryPass = true;
          let productPass = true;
          let topicPass = true;
          let searchReturn = true;

          if (!selectedCategories.includes('all') && selectedCategories.length > 0) {
            const hasSelectedCategory = article.node.resourceCategories.some(category =>
              selectedCategories.includes(category.slug)
            );
            if (!hasSelectedCategory) {
              categoryPass = false;
            }
          } else {
            categoryPass = true;
          }

          if (!selectedProducts.includes('all') && selectedProducts.length > 0) {
            const hasSelectedProduct = article.node.products.some(product => selectedProducts.includes(product.slug));
            if (!hasSelectedProduct) {
              productPass = false;
            }
          } else {
            productPass = true;
          }

          if (!selectedTopics.includes('all') && selectedTopics.length > 0) {
            const hasSelectedTopic = article.node.topics.some(topic => selectedTopics.includes(topic.slug));
            if (!hasSelectedTopic) {
              topicPass = false;
            }
          } else {
            topicPass = true;
          }

          // Filter by search text
          if (searchText.trim() !== '') {
            const articleTitle = article.node.title.toLowerCase();
            const articleContent = article.node.content.toLowerCase();
            const searchQuery = searchText.toLowerCase();
            if (!articleTitle.includes(searchQuery) && !articleContent.includes(searchQuery)) {
              searchReturn = false;
            }
          }

          if (categoryPass && topicPass && productPass && searchReturn) {
            return true;
          } else {
            return false;
          }
        })
      );
    }
  }, [articles, selectedCategories, selectedTopics, selectedProducts, searchText]);

  useEffect(() => {
    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);

    let category = searchParams.get('category');
    let topic = searchParams.get('topics');
    let product = searchParams.get('products');
    const page = searchParams.get('page');
    const search = searchParams.get('search');

    if (topic != null) {
      topic = topic.toLowerCase();
      const topics = topic ? topic.split(',') : [];
      setSelectedTopics(topics);
    }
    if (product != null) {
      product = product.toLowerCase();
      const products = product ? product.split(',') : [];
      setSelectedProducts(products);
    }
    if (category != null) {
      category = category.toLowerCase();
      const categories = category ? category.split(',') : [];
      setSelectedCategories(categories);
    }
    if (page != null) {
      handlePagination(page);
    }
    if (search != null) {
      setSearchText(search);
    }
  }, []);

  useEffect(() => {
    //Re-writes the page url when the page / search text / categories gets update
    const urlParams = new URLSearchParams();
    const categoryString = selectedCategories.join(',');
    const productString = selectedProducts.join(',');
    const topicString = selectedTopics.join(',');
    urlParams.set('category', categoryString);
    urlParams.set('products', productString);
    urlParams.set('topics', topicString);
    urlParams.set('page', currentPage);
    urlParams.set('search', searchText);
    window.history.replaceState(null, '', `${window.location.pathname}?${urlParams.toString()}`);
  }, [selectedCategories, selectedTopics, selectedProducts, currentPage, searchText]);

  const handlePagination = pageNumber => {
    setCurrentPage(pageNumber);
    const targetElement = document.getElementById('news-wrap');
    if (targetElement) {
      gsap.to(window, {
        duration: 0.75,
        scrollTo: { y: targetElement.offsetTop, offsetY: 50 }
      });
    }
  };

  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const currentArticles = filteredArticles.slice(indexOfFirstArticle, indexOfLastArticle);
  const totalPages = Math.ceil(filteredArticles.length / articlesPerPage);

  let uniqueCategories = [];

  if (articles && articles.length > 0) {
    uniqueCategories = Array.from(
      new Set(articles.flatMap(article => article.node.categories.map(category => category.slug)))
    );
    uniqueCategories.sort();
  }

  const handleToggleFilterWrap = () => {
    setOpenFilters(!openFilters);
  };

  return (
    <PageContainer lang={lang}>
      <GlobalStyles />
      <HelmetContainer lang={lang} title="News" />
      <Header lang={lang} />

      <div className={'container'}>
        <NewsGrid id={'news-wrap'}>
          {currentArticles.map((article, index) => (
            <SingleArticle key={index} Theme={'news-page'} ItemData={article} Key={index} key={index} />
          ))}

          {currentArticles.length === 0 && contentLoaded && (
            <NoResultsDisplay>
              <h2>{Copy.noresults}</h2>
            </NoResultsDisplay>
          )}
          {!contentLoaded && (
            <NoResultsDisplay>
              <h2>{Copy.loading}...</h2>
            </NoResultsDisplay>
          )}
        </NewsGrid>

        <PaginationStyles>
          {Array.from({ length: totalPages }, (_, index) => {
            // Display the current page and the pages before and after it
            if (
              index === 0 || // First page
              index === currentPage - 1 || // Current page
              index === totalPages - 1 || // Last page
              (index >= currentPage - 2 && // Pages after the current page
                index <= currentPage) ||
              (index <= currentPage && // Pages before the current page
                index >= currentPage - 1)
            ) {
              return (
                <button key={index} onClick={() => handlePagination(index + 1)} disabled={currentPage === index + 1}>
                  {index + 1}
                </button>
              );
            } else if (
              (index === currentPage - 3 && currentPage > 3) || // Add "..." before pages if there is a gap
              (index === currentPage + 1 && currentPage < totalPages - 2) // Add "..." after pages if there is a gap
            ) {
              return <span key={index}>...</span>;
            }
          })}
        </PaginationStyles>
      </div>

      <Footer />
    </PageContainer>
  );
};
export default NewsTemplate;
